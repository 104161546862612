@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Prevents layout shift when using dynamically-created scrollbars i.e. by using overflow-auto */
  .stable-scrollbar-gutter {
    scrollbar-gutter: stable;
  }
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
