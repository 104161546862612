html,
body,
#__next {
  height: 100%;
}

.iti,
.iti--inline-dropdown {
  display: block !important;
}

.iti__tel-input {
  --iti-input-padding: 0.5rem;
  padding-top: var(--iti-input-padding);
  padding-bottom: var(--iti-input-padding);
}

.fintron-input-phone {
  ul.react-international-phone-country-selector-dropdown {
    /* Default width for component. Only way to style it is using css */
    width: 400px;
  }
}

[data-security-filter='investment-style'] {
  [data-radix-popper-content-wrapper] {
    @apply w-full;
    @apply lg:w-auto;
    @apply px-4;
    @apply lg:px-0;
  }
}
